import { graphql, useStaticQuery } from "gatsby";

export const useWalterPhotos = () => {
  const query = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "image/navbar/menu-foto-walter.png" }) {
        childImageSharp {
          fluid(maxHeight: 1080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      hero: file(relativePath: { eq: "image/walter/walter_hero.png" }) {
        childImageSharp {
          fluid(maxHeight: 1080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img1: file(relativePath: { eq: "image/walter/walter-katalog.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img2: file(relativePath: { eq: "image/walter/walter-milling.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img3: file(relativePath: { eq: "image/walter/walter-turning.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img4: file(relativePath: { eq: "image/walter/walter-drilling.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img5: file(relativePath: { eq: "image/walter/walter-systems.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return query;
};
