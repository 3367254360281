import { useWalterPhotos } from "shared/Hooks/walter/useWalterPhotos";

export const useWalterData = () => {
  const photos = useWalterPhotos();
  return {
    metaInfo: {
      title: "Walter",
      ogDescription:
        "Jeżeli szukasz innowacyjnych rozwiązań, zainwestuj w narzędzia firmy Walter. To producent, który umiejętnie stosuje nowoczesne technologie, dążąc do perfekcji i jak największej precyzji. Jego rozwiązania gwarantują oszczędność przy jednoczesnym zachowaniu wysokich standardów produkcji.",
      ogImage: photos.logo.childImageSharp.src,
    },
    hero: {
      title: "WALTER",
      subtitle:
        "Jeżeli szukasz innowacyjnych rozwiązań, zainwestuj w narzędzia firmy Walter. To producent, który umiejętnie stosuje nowoczesne technologie, dążąc do perfekcji i jak największej precyzji. Jego rozwiązania gwarantują oszczędność przy jednoczesnym zachowaniu wysokich standardów produkcji.",
      bg: photos.hero,
    },
    mainCatalogue: {
      title: "Katalog WALTER",
      subtitle:
        "Uchwyty dostosowane do potrzeb każdego przemysłu: bez względu na to czy wymaga on większej elastyczności, minimalizacji wibracji czy dużej siły tnącej.",
      links: [
        {
          url: "/catalogs/walter/general-catalogue-2016-pl.pdf",
          text: "Katalog Główny WALTER",
        },
      ],
      bigImg: true,
      src: photos.img1,
    },
    milling: {
      title: "Frezowanie",
      subtitle:
        "Większa trwałość narzędzi, maksymalne bezpieczeństwo procesu, a także najwyższa stabilność i dokładność. Systemy do frezowania Walter charakteryzuje wydajność produkcji i efektywność ekonomiczna przy jednoczesnym zachowaniu standardów jak najbardziej przyjaznych dla środowiska. ",
      links: [
        {
          url: "/catalogs/walter/flyer-conefit-pl.pdf",
          text: "Modułowy system do frezowania",
        },
        {
          url: "/catalogs/walter/flyer-dynamic-milling-pl.pdf",
          text: "Dynamiczne frezowanie",
        },
        {
          url: "/catalogs/walter/flyer-m4000-2016-pl.pdf",
          text: "System frezów M4000",
        },
      ],
      bigImg: true,
      src: photos.img2,
    },
    materials: {
      title: "Toczenie",
      subtitle:
        "Narzędzia Walter pozwalają na skrócenie czasu obróbki i zapewniają najwyższe parametry skrawania. To technologie wyjątkowe w skali światowej, zapewniające odporność na zmienne obciążenie termiczne podczas obróbki na mokro i na sucho oraz gwarantujące wzrost wydajności.",
      links: [
        {
          url: "/catalogs/walter/flyer-ttg-2016-pl.pdf",
          text: "Materiały do toczenia i skrawania: Tiger Tec Gold",
        },
        {
          url:
            "/catalogs/walter/flyer-tts-turning-milling-2011-pl.pdf",
          text: "Materiały do toczenia i skrawania: Tiger Tec Silver",
        },
        {
          url:
            "/catalogs/walter/flyer-perform-line-turning-2019-pl.pdf",
          text: "Płytki tokarskie",
        },
        {
          url:
            "/catalogs/walter/flyer-boring-precision-boring-pl.pdf",
          text: "Wytaczanie zgrubne i dokładne",
        },
      ],
      bigImg: true,
      src: photos.img3,
    },
    dc170: {
      title: "DC170 - ikona wiercenia",
      subtitle:
        "DC170 to ostrze skrawające o unikatowej konstrukcji, która zapewnia największą stabilność procesu i znacznie lepszą jakość otworów. To ikona nowej klasy wydajności - specjalna orientacja jej łysinek pozwala na redukcję drgań do niezbędnego minimum, dzięki czemu wzrasta bezpieczeństwo procesu. DC170 to wysoka efektywność, ale również oszczędność.",
      links: [
        {
          url: "/catalogs/walter/flyer-dc170-pl.pdf",
          text: "DC170",
        },
      ],
      bigImg: true,
      src: photos.img4,
    },
    vending: {
      title: "Systemy wydawania narzędzi",
      subtitle:
        "Systemy wydawania Walter to w pełni konfigurowalny, przejrzysty sposób gospodarowania narzędziami. Szafy wyposażone w komfortowe ekrany dotykowe mają intuicyjny interfejs. Ich zastosowanie pozwala na redukcję stanów magazynowych, a do tego wyposażenie produkcji jest dostępne przez cały czas - bez przestojów.",
      links: [
        {
          url:
            "/catalogs/walter/flyer-vending-network-line-pl.pdf",
          text: "System wydawania narzędzi: Vending Network Line",
        },
        {
          url:
            "/catalogs/walter/flyer-walter-vending-d-line-pl.pdf",
          text: "System wydawania narzędzi: Walter Vending Line",
        },
      ],
      bigImg: true,
      src: photos.img5,
    },
  };
};
