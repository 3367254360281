import React from "react";
import Section from "components/Section/Section";
import Hero from "components/Hero/Hero";
import SEO from "components/seo";

import {SectionsWrappper} from "../../shared/Wrappers/wrappers";

const Walter = ({data}) => {
  return (
    <>
      <SEO
        title={data.metaInfo.title}
        description={data.metaInfo.ogDescription}
      />
      <Hero data={data.hero} color="black" reverse>
      </Hero>
      <SectionsWrappper>
        <Section data={data.mainCatalogue}/>
        <Section data={data.milling} reverse/>
        <Section data={data.materials}/>
        <Section data={data.dc170} reverse/>
        <Section data={data.vending}/>
      </SectionsWrappper>

    </>
  );
};

export default Walter;
