import React from "react";
import { useWalterData } from "lang/walter";
import Walter from "sections/Walter/Walter";

const WalterPage = () => {
  const data = useWalterData();
  return <Walter data={data} />;
};

export default WalterPage;
